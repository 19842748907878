import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { BackgroundContainer, StyledContainer } from "../../Styled/Container"

const Container = styled(StyledContainer)`
  ${tw`pb-4-8 md:pb-6-4`}
`

const Point = tw.div`
  flex flex-wrap pb-2-4 md:pb-3-2 items-center
`

const Title = tw.h3`
  block w-full md:w-1/3 font-display text-20 leading-1.5 md:text-32 md:leading-1.75
`

const Description = tw.p`
  block w-full md:w-2/3 md:pl-3-2 text-16 leading-1.75
`

const SellingPointsList = ({ points, width, background }) => {
  return (
    <BackgroundContainer background={background}>
      {points && (
        <Container width={width}>
          {points.map(point => (
            <Point key={point.title}>
              <Title>{point.title}</Title>
              <Description>{point.description}</Description>
            </Point>
          ))}
        </Container>
      )}
    </BackgroundContainer>
  )
}

export const SellingPointsListPropTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  width: PropTypes.oneOf([`full`, `xl`, `lg`, `md`]),
  background: PropTypes.string,
}

SellingPointsList.defaultProps = {
  width: `lg`,
  background: `transparent`,
}

SellingPointsList.propTypes = SellingPointsListPropTypes

export default SellingPointsList
