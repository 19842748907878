import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { BackgroundContainer, StyledContainer } from "../../Styled/Container"

const Container = styled(StyledContainer)`
  ${tw`pb-4-8 md:pb-8`}
`
const Wrapper = tw.div`lg:-mx-4 flex flex-col lg:flex-row`
const Desktop = tw.div`hidden lg:block w-full relative mx-4`
const Mobile = tw.div`block lg:hidden`
const CircleWrapper = tw.div`aspect-ratio-square relative`
const Circle = tw.div`absolute inset-0 rounded-full border-light-green bg-light-green py-5-6 px-4`
const Point = tw.div`flex flex-wrap pb-2-4 w-full`
const Title = tw.h3`block w-full text-28 leading-1.29 md:text-32 md:leading-1.31 font-display text-left lg:text-center md:mb-1-2`
const Description = tw.p`block w-full text-16 leading-1.75 text-left lg:text-center md:px-0-4`

const SellingPointsCircles = ({ points, width, background }) => {
  return (
    <BackgroundContainer background={background}>
      {points && (
        <Container width={width}>
          <Wrapper>
            {points.map(point => (
              <React.Fragment key={point.title}>
                <Desktop>
                  <CircleWrapper>
                    <Circle>
                      <Point>
                        <Title>{point.title}</Title>
                        <Description>{point.description}</Description>
                      </Point>
                    </Circle>
                  </CircleWrapper>
                </Desktop>
                <Mobile>
                  <Point>
                    <Title>{point.title}</Title>
                    <Description>{point.description}</Description>
                  </Point>
                </Mobile>
              </React.Fragment>
            ))}
          </Wrapper>
        </Container>
      )}
    </BackgroundContainer>
  )
}

export const SellingPointsCirclesPropTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  width: PropTypes.oneOf([`full`, `xl`, `lg`, `md`]),
  background: PropTypes.string,
}

SellingPointsCircles.defaultProps = {
  background: `transparent`,
  width: `lg`,
}

SellingPointsCircles.propTypes = SellingPointsCirclesPropTypes

export default SellingPointsCircles
