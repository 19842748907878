import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withFeaturedImageContent } from "./withFeaturedImageContent"
import Image from "../../Image/Image"
import Glider from "../../Glider"
import ImageContent from "../../ImageContent"
import { BackgroundContainer, StyledContainer } from "../../Styled/Container"

const Container = styled(StyledContainer)`
  ${tw`relative max-w-144 mx-auto`}
`

const ContentWrapper = tw.div`
  h-full flex items-center py-1-6 bg-lightest
`

const Content = tw.div`
  max-w-50 mx-auto px-1-6 py-3-2
`

const Logo = tw.div`
  absolute max-w-27-2 w-full bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 z-10 hidden md:block
`

const FeaturedImageContent = withFeaturedImageContent(({ sections, logo }) => {
  return (
    <BackgroundContainer background={`light`}>
      {sections && (
        <Container width={`full`}>
          <Logo>
            <Image image={logo.src} alt={logo.alt} ratio={`1/1`} />
          </Logo>
          {sections.map((section, index) => (
            <ImageContent key={index} image={section.image} reverse={section.reverse}>
              {section.contents && (
                <ContentWrapper>
                  {section.contents?.length > 1 ? (
                    <Glider
                      hasDots
                      customizedSettings={{
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      }}
                      noResponsive
                    >
                      {section.contents.map((content, index) => (
                        <div key={index}>
                          <Content>{content}</Content>
                        </div>
                      ))}
                    </Glider>
                  ) : (
                    <Content>{section.contents[0]}</Content>
                  )}
                </ContentWrapper>
              )}
            </ImageContent>
          ))}
        </Container>
      )}
    </BackgroundContainer>
  )
})

FeaturedImageContent.propTypes = {
  logo: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      reverse: PropTypes.bool,
      image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
      contents: PropTypes.arrayOf(PropTypes.node),
    })
  ),
}

export default FeaturedImageContent
