import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import InstagramCard from "./InstagramCard"
import { StyledContainer, BackgroundContainer } from "../../Styled/Container"
import Icon from "../../Icon"
import Glider from "../../Glider"
import InstagramPopup from "./InstagramPopup"
import { GatsbyImageSourceProps } from "../../../props/ImageProps"
import { withInstagram } from "./withInstagram"

const Container = styled(StyledContainer)`
  ${tw`pt-3-2 md:pt-6-4 pb-3-2 md:pb-9-2 px-0-8 lg:px-4-8 max-w-xl mx-auto`}
`

const Title = tw.div`
  text-28 leading-1.29 sm:text-56 sm:leading-1.36 font-display text-center flex flex-row items-center justify-center
`

const SubTitle = tw.h3`
  text-16 leading-1.5 text-center px-2-4
`

const GliderWrapper = tw.div`
  mt-2-4 md:mt-5-6
`

const Instagram = withInstagram(({ title, subTitle, background, buttonText, contents, popupActiveContent, setPopupActiveContent }) => {
  return (
    <BackgroundContainer background={background}>
      {contents && (
        <Container width={`full`}>
          {title && (
            <Title>
              {title}
              <Icon name={`instagram`} size={`xlarge`} />
            </Title>
          )}
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
          <GliderWrapper>
            <Glider>
              {contents.map((content, index) => (
                <InstagramCard
                  key={index}
                  buttonText={buttonText}
                  image={content.image}
                  userName={content.userName}
                  product={content.product}
                  handleActive={() => setPopupActiveContent(index)}
                />
              ))}
            </Glider>
          </GliderWrapper>
          <InstagramPopup
            userName={contents[popupActiveContent]?.userName}
            image={contents[popupActiveContent]?.image}
            product={contents[popupActiveContent]?.product}
            active={popupActiveContent !== -1}
            setActive={() => setPopupActiveContent(-1)}
          />
        </Container>
      )}
    </BackgroundContainer>
  )
})

Instagram.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  background: PropTypes.string,
  buttonText: PropTypes.string,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({
        image: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
        }),
        title: PropTypes.string,
        subTitle: PropTypes.string,
        link: PropTypes.string,
        defaultVariant: PropTypes.object,
      }),
      userName: PropTypes.string,
      image: PropTypes.shape(GatsbyImageSourceProps),
    })
  ),
}

export default Instagram
