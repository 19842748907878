import React from "react"

import { useCore } from "../../../hooks/useCore"

export const withTextGrid = Component => ({ name = "TextGrid", contents, ...props }) => {
  const {
    helpers: { sanityContent },
  } = useCore()

  const mappedContents = contents?.map(content => sanityContent(content?.content))

  Component.displayName = name
  return <Component {...props} contents={mappedContents} />
}
