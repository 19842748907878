import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { navigate } from "gatsby"

import { withBanner } from "./withBanner"
import { StyledButton } from "../../Styled/Button"

const styles = {
  alignment: {
    center: tw`justify-center`,
    right: tw`justify-center sm:justify-end`,
    left: tw`justify-center sm:justify-start`,
  },
  verticalAlignment: {
    center: tw`items-center`,
    top: tw`items-center sm:items-start`,
    bottom: tw`items-center sm:items-end`,
  },
}

const Wrapper = tw.div`
  relative
`

const BannerButton = styled(StyledButton)`
  ${tw`px-3-2 md:px-8`}
`

const ButtonWrapper = styled.div`
  ${tw`absolute w-full h-full inset-0 flex sm:p-7-2`}
  ${({ alignment }) => styles.alignment[alignment]}
  ${({ verticalAlignment }) => styles.verticalAlignment[verticalAlignment]}
`

const Image = tw.img`
  w-full
`

const Banner = withBanner(({ image, link, text, alignment, verticalAlignment }) => {
  return (
    <Wrapper>
      <Image src={image.src} alt={image.alt} />
      <ButtonWrapper alignment={alignment} verticalAlignment={verticalAlignment}>
        {text && (
          <BannerButton onClick={() => navigate(link)} colour={`light`} height={`88-56`} textSize={`32`}>
            {text}
          </BannerButton>
        )}
      </ButtonWrapper>
    </Wrapper>
  )
})

Banner.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
  }),
  link: PropTypes.string,
  text: PropTypes.string,
  alignment: PropTypes.oneOf(["center", "right", "left"]),
  verticalAlignment: PropTypes.oneOf(["top", "center", "bottom"]),
}

Banner.defaultProps = {
  alignment: "left",
  verticalAlignment: "center",
}

export default Banner
