import React from "react"

import { useCore } from "../../../hooks/useCore"

export const withRichtext = Component => ({ name = "Richtext", content, ...props }) => {
  const {
    helpers: { sanityContent },
  } = useCore()

  const normalisedContent = sanityContent(content)
  Component.displayName = name
  return <Component {...props} content={normalisedContent} />
}
