import React, { lazy } from "react"

import { useCore } from "../../hooks/useCore"

export const withSections = Component => ({ name = "Sections", page, ...props }) => {
  const {
    helpers: { ErrorBoundary },
  } = useCore()

  const sections = page?.content?.map(section => {
    const name = section?._type?.replace("section", "")
    const SectionComponent =
      typeof window !== `undefined` ? lazy(() => import(`../Section/${name}/${name}`)) : require(`../Section/${name}/${name}`).default
    return {
      ...section,
      component: () => <SectionComponent {...props} {...section} />,
    }
  })

  Component.displayName = name
  return (
    <ErrorBoundary>
      <Component {...props} content={sections} />
    </ErrorBoundary>
  )
}
