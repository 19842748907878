import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import Image from "../../Image/Image"
import { StyledButton } from "../../Styled/Button"

const Wrapper = styled.div`
  ${tw`pt-1-6 px-0-8 sm:p-1-2 pb-0-8`}
`

const Button = styled(StyledButton)`
  ${tw`w-full bg-lightest`}
`

const StyledLink = styled(Link)`
  ${tw`select-none`}
`

const FeaturedCarouselCard = ({ image, link, title }) => (
  <Wrapper>
    <StyledLink to={link}>
      <Image image={image.src} alt={`${image.src}`} ratio={`19/25`} />
      <Button height={`56-34`} textSize={`28-20`}>
        {title}
      </Button>
    </StyledLink>
  </Wrapper>
)

export const FeaturedCarouselCardPropTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

FeaturedCarouselCard.propTypes = FeaturedCarouselCardPropTypes

export default FeaturedCarouselCard
