import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { withFeaturedContent } from "./withFeaturedContent"
import FeaturedContentCard from "./FeaturedContentCard"
import { BackgroundContainer } from "../../Styled/Container"

const Container = styled.div`
  ${tw`w-full max-w-full`}
  ${({ spacing, blog }) => {
    if (!spacing) return null
    return blog ? tw`py-0-8 md:py-2-4` : tw`pt-5-6 sm:pt-8-8 pb-4 sm:pb-8`
  }}
  ${({ cols, spacing }) => {
    if (cols === 1) return spacing ? tw`max-w-xl mx-auto px-6-4 sm:px-7-2 md:px-16-8 lg:px-28-8` : null
    if (cols === 2) return spacing ? tw`max-w-xl mx-auto px-1-2 sm:px-3-6 md:px-7-2 lg:px-17-6` : null
    if (cols > 2) return spacing ? tw`max-w-xl mx-auto px-1-2 sm:px-3-6 md:px-7-2` : null
  }}
`

const Wrapper = tw.div`
  flex flex-wrap
`

const Title = tw.h2`
  text-28 leading-1.29 md:text-56 md:leading-1.36 mb-1 md:mb-2-4 text-center font-display
`

const CTAWrapper = tw.div`
  flex justify-center
`

const StyledLink = styled(Link)`
  ${tw`inline-block md:hidden text-16 leading-1.12 pb-0-4 mb-3-2 border-b`}
`

const FeaturedContent = withFeaturedContent(({ spacing, title, text, link, cols, contents }) => {
  return (
    <BackgroundContainer background={`lightest`}>
      {contents && (
        <Container spacing={spacing ? "true" : undefined} cols={cols}>
          {title && <Title>{title}</Title>}
          {text && link ? (
            <CTAWrapper>
              <StyledLink to={link}>{text}</StyledLink>
            </CTAWrapper>
          ) : null}
          <Wrapper>
            {contents.map((content, index) => (
              <FeaturedContentCard
                key={index}
                spacing={spacing}
                cols={cols}
                title={content.title}
                subTitle={content.subTitle}
                image={content.image}
                buttonLink={content.buttonLink}
              />
            ))}
          </Wrapper>
        </Container>
      )}
    </BackgroundContainer>
  )
})

FeaturedContent.propTypes = {
  spacing: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  background: PropTypes.string,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subTitle: PropTypes.string,
      image: PropTypes.object,
      buttonLink: PropTypes.shape({
        link: PropTypes.string,
        title: PropTypes.string,
      }),
      spacing: PropTypes.bool,
      cols: PropTypes.oneOf([1, 2, 4]),
    })
  ),
  blog: PropTypes.bool,
}

FeaturedContent.defaultProps = {
  title: "",
  link: "",
  text: "",
  cols: 4,
}

export default FeaturedContent
