import React from "react"
import PropTypes from "prop-types"

import { withSellingPoints } from "./withSellingPoints"
import { BackgroundContainer } from "../../Styled/Container"
import SellingPointsList from "./SellingPointsList"
import SellingPointsCircles from "./SellingPointsCircles"

const SellingPoints = withSellingPoints(({ background, points, pointsWidth, style }) => {
  return (
    <BackgroundContainer background={background}>
      {style === "list" && <SellingPointsList points={points} width={pointsWidth} />}
      {style === "circle" && <SellingPointsCircles points={points} width={pointsWidth} />}
    </BackgroundContainer>
  )
})

export const SellingPointsPropTypes = {
  background: PropTypes.string,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  pointsWidth: PropTypes.oneOf([`full`, `xl`, `lg`, `md`]),
  style: PropTypes.oneOf([`list`, `circle`]),
}

SellingPoints.propTypes = SellingPointsPropTypes

SellingPoints.defaultProps = {
  pointsWidth: `lg`,
  style: `list`,
}

export default SellingPoints
