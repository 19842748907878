import React from "react"

import { useCore } from "../../../hooks/useCore"
import { useShopify } from "../../../hooks/useShopify"

export const withInstagramPopup = Component => ({ name = "InstagramPopup", product, ...props }) => {
  const {
    graphql: {
      queries: { GET_PRODUCT },
    },
  } = useCore()
  const { useQuery, productNormaliser } = useShopify()

  let formatted = false
  let mappedProduct = {}

  const { data, loading, error } =
    typeof window !== `undefined` && product
      ? useQuery(GET_PRODUCT, {
          fetchPolicy: `cache-and-network`,
          variables: {
            id: product?.shopifyId,
            handle: product?.shopifyHandle,
            parentQuery: `tag:'parent:${product?.shopifyHandle}'`,
            firstCollections: 1,
            firstImages: 1,
            firstMetafields: 1,
            firstVariants: 1,
          },
        })
      : { data: false, loading: false, error: false }

  if (error) console.error(error)

  if (!loading && data && !formatted) {
    const normalisedProduct = productNormaliser(data.product)
    mappedProduct.image = normalisedProduct?.images[0]
    mappedProduct.link = product.url
    mappedProduct.title = normalisedProduct?.title.split(" ")[0]
    mappedProduct.subTitle = normalisedProduct?.title.split(" ")[1]
    mappedProduct.defaultVariant = normalisedProduct?.variants[0]
    formatted = true
  }

  Component.displayName = name
  return <Component product={mappedProduct} {...props} />
}
