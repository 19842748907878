import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withTextGrid } from "./withTextGrid"
import { StyledContainer, BackgroundContainer } from "../../Styled/Container"

const Container = styled(StyledContainer)`
  ${({ padding }) => (padding === `yes` ? tw`py-3-2 md:py-7-2` : tw`py-0`)}
`

const Wrapper = styled.div`
  ${tw`flex flex-wrap`}
  ${({ cols }) => (cols === `2` ? tw`md:-mx-3-2` : null)}
`

const RichTextWrapper = styled.div`
  ${({ cols }) => {
    if (cols === `1`) return tw`w-full`
    if (cols === `2`) return tw`w-full py-1-6 md:w-1/2 md:px-3-2`
  }}
`

const TextGrid = withTextGrid(({ contents, background, width, padding, cols }) => (
  <BackgroundContainer background={background}>
    <Container width={width} padding={padding}>
      <Wrapper cols={cols}>
        {contents?.map((content, index) => (
          <RichTextWrapper key={index} cols={cols}>
            <div className={"rte"}>{content}</div>
          </RichTextWrapper>
        ))}
      </Wrapper>
    </Container>
  </BackgroundContainer>
))

TextGrid.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.object),
  background: PropTypes.oneOf([`transparent`, `light`, `orange`, `green`, `lightest`]),
  width: PropTypes.oneOf([`sm`, `md`, `lg`, `xl`]),
  padding: PropTypes.oneOf([`yes`, `no`]),
  cols: PropTypes.oneOf([`1`, `2`]),
}

TextGrid.defaultProps = {
  width: "xl",
  background: "light",
  padding: true,
}

export default TextGrid
