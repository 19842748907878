import React from "react"

export const withFeaturedContentCard = Component => ({ name = "FeaturedContentCard", buttonLink, image, ...props }) => {
  const { title: text, link } = buttonLink
  const featuredImage = {
    src: image?.asset?.url,
    alt: image?.alt,
  }
  Component.displayName = name
  return <Component {...props} text={text} link={link} image={featuredImage} />
}
