import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { withFeaturedCarousel } from "./withFeaturedCarousel"
import { isBrowser } from "../../../utils/domUtils"
import FeaturedCarouselCard, { FeaturedCarouselCardPropTypes } from "./FeaturedCarouselCard"
import { StyledContainer, BackgroundContainer } from "../../Styled/Container"
import { StyledH1 } from "../../Styled/Text"
import Glider from "../../Glider"

const Container = styled(StyledContainer)`
  ${tw`pt-3-2 sm:pt-6-4 pb-3-2 sm:pb-9-2 px-0-8 sm:px-4-8 md:px-7-2 max-w-xl mx-auto`}
`

const Title = styled(StyledH1)`
  ${tw`mb-0-8 text-center`}
`

const StyledLinkWrapper = tw.div`
  flex justify-center
`

const StyledLink = styled(Link)`
  ${tw`text-16 leading-1.5 border-b hover:text-orange hover:border-orange`}
`

const GliderWrapper = tw.div`
  mt-4 md:mt-7-2
`

const FeaturedCarousels = withFeaturedCarousel(({ title, link, contents }) => {
  return (
    <BackgroundContainer background={`light`}>
      <Container width={`full`}>
        {title && <Title>{title}</Title>}
        {link && (
          <StyledLinkWrapper>
            <StyledLink to={link}>See all</StyledLink>
          </StyledLinkWrapper>
        )}
        {isBrowser && contents && (
          <GliderWrapper>
            <Glider
              customizedSettings={{
                responsive: [
                  {
                    breakpoint: 415,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 1,
                    },
                  },
                  {
                    breakpoint: 769,
                    settings: {
                      slidesToShow: 5,
                      slidesToScroll: 1,
                    },
                  },
                  {
                    breakpoint: 1367,
                    settings: {
                      slidesToShow: 6,
                      slidesToScroll: 1,
                    },
                  },
                ],
              }}
            >
              {contents?.map((content, index) => (
                <FeaturedCarouselCard key={index} image={content.image} link={content.link} title={content.title} />
              ))}
            </Glider>
          </GliderWrapper>
        )}
      </Container>
    </BackgroundContainer>
  )
})

FeaturedCarousels.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  background: PropTypes.string,
  contents: PropTypes.arrayOf(PropTypes.shape(FeaturedCarouselCardPropTypes)),
}

export default FeaturedCarousels
