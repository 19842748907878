import React, { useState } from "react"

import { useInstagram } from "../../../hooks/useInstagram"

export const withInstagram = Component => ({ name = "Instagram", ...props }) => {
  const [popupActiveContent, setPopupActiveContent] = useState(-1)
  const { items } = useInstagram()
  Component.displayName = name
  return <Component contents={items} popupActiveContent={popupActiveContent} setPopupActiveContent={setPopupActiveContent} {...props} />
}
