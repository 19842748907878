import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

import ImageCTA from "../../Image/ImageCTA"
import { GatsbyImageSourceProps } from "../../../props/ImageProps"

const Wrapper = tw.div`
  pt-1-6 px-0-8 md:p-1-6 pb-0-8
`

const MobileWrapper = tw.div`
  relative
`

const MobileMask = tw.div`
  block md:hidden z-10 absolute inset-0 select-none
`

const InstagramCard = ({ buttonText, image, handleActive }) => {
  return (
    <Wrapper>
      <MobileWrapper>
        <MobileMask onClick={handleActive} />
      </MobileWrapper>
      <ImageCTA image={image} buttonText={buttonText} onClick={handleActive} />
    </Wrapper>
  )
}

InstagramCard.propTypes = {
  buttonText: PropTypes.string,
  image: PropTypes.shape(GatsbyImageSourceProps),
  handleActive: PropTypes.func,
}

export default InstagramCard
