import React from "react"

export const withBanner = Component => ({ name = "Banner", buttonLink, image, ...props }) => {
  const { link, title } = buttonLink
  const featuredImage = {
    src: image?.asset?.url,
    alt: image?.alt,
  }
  Component.displayName = name
  return <Component {...props} link={link} text={title} image={featuredImage} />
}
