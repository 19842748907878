import React from "react"

export const withFeaturedCarousel = Component => ({ name = "FeaturedCarousel", titleLink, contents, ...props }) => {
  const { title, link } = titleLink
  const mappedContents = contents.map(content => ({
    title: content?.buttonLink?.title,
    link: content?.buttonLink.link,
    image: {
      src: content?.image?.asset?.url,
      alt: content?.image?.alt,
    },
  }))
  Component.displayName = name
  return <Component {...props} title={title} link={link} contents={mappedContents} />
}
