import React from "react"

export const withHero = Component => ({ name = "Hero", content, ...props }) => {
  const items = content.map(item => ({
    image: {
      src: item.image?.asset?.url,
      alt: item.image?.alt,
    },
    buttonLink: item?.buttonLink?.link,
    buttonText: item?.buttonLink?.title,
  }))
  Component.displayName = name
  return <Component {...props} items={items} />
}
