import React from "react"

import { useCore } from "../../../hooks/useCore"

export const withFeaturedImageContent = Component => ({ name = "FeaturedImageContent", sectionFirst, sectionSecond, logo, ...props }) => {
  const {
    helpers: { sanityContent },
  } = useCore()
  const sections = [
    {
      reverse: sectionFirst?.reverse,
      contents: sectionFirst?.contents?.map(content => sanityContent(content?.content)),
      image: {
        src: sectionFirst?.image?.asset?.url,
        alt: sectionFirst?.image?.alt,
      },
    },
    {
      reverse: sectionSecond?.reverse,
      contents: sectionSecond?.contents?.map(content => sanityContent(content?.content)),
      image: {
        src: sectionSecond?.image?.asset?.url,
        alt: sectionSecond?.image?.alt,
      },
    },
  ]
  const logoImage = {
    src: logo?.asset?.url,
    alt: logo?.alt,
  }
  Component.displayName = name
  return <Component {...props} logo={logoImage} sections={sections} />
}
