import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withRichtext } from "./withRichtext"
import { BackgroundContainer, StyledContainer } from "../../Styled/Container"

const Container = styled(StyledContainer)`
  ${({ padding }) => (padding === "yes" ? tw`py-6-4` : null)}
`

const Richtext = withRichtext(({ content, width, background, padding }) => {
  return (
    <BackgroundContainer background={background}>
      <Container width={width} padding={padding}>
        <div className={`rte`}>{content}</div>
      </Container>
    </BackgroundContainer>
  )
})

export default Richtext
