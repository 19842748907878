import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

import HeroButton from "./HeroButton"

const Wrapper = tw.div`
  w-1/2
`

const ImageWrapper = tw.div`
  aspect-ratio-6-5 sm:aspect-ratio-square relative
`

const InnerImageWrapper = tw.div`
  absolute inset-0
`

const ButtonWrapper = tw.div`
  flex items-end md:items-center justify-center w-full h-full absolute inset-0
`

const Image = tw.img`
  w-full h-full
`

const HeroImage = ({ image, buttonLink, buttonText }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <InnerImageWrapper>
          <Image src={image.src} alt={image.alt} />
          <ButtonWrapper>{buttonText && <HeroButton link={buttonLink}>{buttonText}</HeroButton>}</ButtonWrapper>
        </InnerImageWrapper>
      </ImageWrapper>
      {buttonText && (
        <HeroButton link={buttonLink} mobile>
          {buttonText}
        </HeroButton>
      )}
    </Wrapper>
  )
}

export const HeroImagePropTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
}

HeroImage.defaultProps = {
  buttonLink: "",
}

HeroImage.propTypes = HeroImagePropTypes

export default HeroImage
