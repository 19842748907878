import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { navigate } from "gatsby"

import { withInstagramPopup } from "./withInstagramPopup"
import ModalsPopup from "../../Modals/ModalsPopup"
import Image from "../../Image/Image"
import Price from "../../Price/Price"
import { StyledButton } from "../../Styled/Button"
import { GatsbyImageSourceProps } from "../../../props/ImageProps"

const Content = tw.div`
  relative w-full flex flex-wrap px-4 md:px-5-6 pb-4-8 md:py-5-6 pt-7-2
`

const ImageWrapper = tw.div`w-full md:w-1/2`

const ProductWrapper = tw.div`w-full md:w-1/2 flex flex-wrap md:max-w-27-2 mx-auto text-center justify-between md:pl-4-8`

const DesktopUserName = tw.p`
  hidden md:block text-16 leading-1.31 mb-2 w-full
`

const MobileUserName = tw.p`
  block md:hidden text-16 leading-1.31 absolute top-2-4 left-0 w-full text-center
`

const Title = tw.h4`
  text-20 leading-1.3 md:text-32 md:leading-1.31 font-display mb-1-2 text-left md:text-center
`
const SubTitle = tw.p`
  text-14 md:text-16 leading-1.31 mb-0-4
`
const Button = styled(StyledButton)`
  ${tw`w-full`}
`

const MobileWrapper = tw.div`
  w-1/2 md:w-full flex flex-col px-0-4 md:px-0 py-1-6 md:py-0 text-left md:text-center
`

const StyledPrice = styled(Price)`
  ${tw`text-14 leading-1.29 sm:text-16 sm:leading-1.31 sm:mb-1-6`}
`

const InstagramPopup = withInstagramPopup(({ userName, image, product, active, setActive }) => {
  return (
    <ModalsPopup active={active} setActive={setActive}>
      <Content>
        <MobileUserName>{userName}</MobileUserName>
        <ImageWrapper>
          <Image image={image} alt={product?.title} ratio={`8/9`} />
        </ImageWrapper>
        {product && (
          <ProductWrapper>
            <DesktopUserName>{userName}</DesktopUserName>
            <MobileWrapper>
              <Image image={product?.image?.src} alt={product?.image?.alt} ratio={`9/8`} />
            </MobileWrapper>
            <MobileWrapper>
              <Title>{product?.title}</Title>
              <SubTitle>{product?.subTitle}</SubTitle>
              <StyledPrice presentmentPrices={product?.defaultVariant?.presentmentPrices} />
            </MobileWrapper>
            <Button
              onClick={() => {
                setActive(false)
                navigate(product?.link)
              }}
              height={`72-56`}
              textSize={`16`}
              colour={`lightest`}
              border
            >
              Shop Now
            </Button>
          </ProductWrapper>
        )}
      </Content>
    </ModalsPopup>
  )
})

InstagramPopup.propTypes = {
  userName: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(GatsbyImageSourceProps)]),
  product: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
    title: PropTypes.string,
    subTitle: PropTypes.string,
    link: PropTypes.string,
    defaultVariant: PropTypes.object,
  }),
  active: PropTypes.bool,
  setActive: PropTypes.func,
}

export default InstagramPopup
