import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import ReactPlayer from "react-player"

import { withVideo } from "./withVideo"
import { BackgroundContainer, StyledContainer } from "../../Styled/Container"

const Container = styled(StyledContainer)`
  ${({ padding }) => (padding === "yes" ? `py-6-4` : null)}
`

const VideoWrapper = tw.div`
  aspect-ratio-landscape relative
`

const InnerVideoWrapper = tw.div`
  absolute inset-0
`

const Video = withVideo(({ url, width, padding }) => {
  return (
    <BackgroundContainer background={`lightest`}>
      {url && (
        <Container width={width} padding={padding}>
          <VideoWrapper>
            <InnerVideoWrapper>
              <ReactPlayer url={url} width={`100%`} height={`100%`} />
            </InnerVideoWrapper>
          </VideoWrapper>
        </Container>
      )}
    </BackgroundContainer>
  )
})

Video.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.string,
}

export default Video
