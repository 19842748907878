import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withHero } from "./withHero"
import HeroImage, { HeroImagePropTypes } from "./HeroImage"
import { StyledContainer } from "../../Styled/Container"

const Container = styled(StyledContainer)`
  ${tw`flex flex-wrap`}
`

const Hero = withHero(({ items }) => {
  return (
    <Container width={`full`}>
      {items?.map((item, index) => (
        <HeroImage key={index} image={item.image} buttonLink={item.buttonLink} buttonText={item.buttonText} />
      ))}
    </Container>
  )
})

Hero.protoTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(HeroImagePropTypes)),
}

Hero.defaultProps = {
  items: [],
}

export default Hero
