import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withFeaturedImage } from "./withFeaturedImage"
import Glider from "../../Glider"
import Image from "../../Image/Image"
import { BackgroundContainer, StyledContainer } from "../../Styled/Container"

const Container = styled(StyledContainer)`
  ${tw`py-3-2`}
`

const FeaturedImage = withFeaturedImage(({ images }) => (
  <BackgroundContainer background={`transparent`}>
    {images && (
      <Container width={`full`}>
        <Glider noResponsive>
          {images.map((image, index) => (
            <div key={index}>
              <Image image={image.src} alt={image?.alt} ratio={`5/6`} />
            </div>
          ))}
        </Glider>
      </Container>
    )}
  </BackgroundContainer>
))

FeaturedImage.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
}

export default FeaturedImage
