import React from "react"

export const withFeaturedImage = Component => ({ name = "FeaturedImage", content, ...props }) => {
  const images = content.map(image => ({
    src: image?.asset?.url,
    alt: image?.alt,
  }))
  Component.displayName = name
  return <Component {...props} images={images} />
}
