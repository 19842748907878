import { useStaticQuery, graphql } from "gatsby"

import { useApp } from "./useApp"

export const useInstagram = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { images } = useStaticQuery(graphql`
    query INSTGRAM_FEED {
      images: allSanityInstagram(limit: 6) {
        edges {
          node {
            ...GatsbyInstagramFragment
          }
        }
      }
    }
  `)

  const items = images?.edges?.map(({ node }) => ({
    image: node?.image?.asset?.fluid,
    product: {
      shopifyHandle: node?.product?.shopify?.shopifyHandle,
      shopifyId: node?.product?.shopify?.shopifyId,
      url: `${routes?.PRODUCT}/${node?.product?.shopify?.shopifyHandle}`,
    },
  }))

  return {
    items,
  }
}
