import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { navigate } from "gatsby"

import { StyledButton } from "../../Styled/Button"
import Icon from "../../Icon"

const StyledIcon = styled(Icon)`
  ${tw`group-hover:transform group-hover:translate-x-0-4 transition-transform duration-slow pl-0-8`}
`

const Button = styled(StyledButton)`
  ${tw`w-full md:max-w-30`}
  ${({ mobile }) => (mobile ? tw`flex md:hidden bg-lighter border-l border-r border-light` : tw`hidden md:flex`)}
`

const HeroButton = ({ children, className, link, mobile }) => (
  <Button className={`group ${className}`} colour={`light`} height={`88-56`} textSize={`32`} mobile={mobile} onClick={() => navigate(link)} icon>
    {children}
    <StyledIcon name={`vintageArrow`} size={`medium`} />
  </Button>
)

HeroButton.propTypes = {
  className: PropTypes.node,
  mobile: PropTypes.bool,
  children: PropTypes.string,
  link: PropTypes.string,
}

HeroButton.defaultProps = {
  className: "",
}

export default HeroButton
