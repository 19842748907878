import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { withFeaturedContentCard } from "./withFeaturedContentCard"
import ImageCTA from "../../Image/ImageCTA"

const Wrapper = styled.div`
  ${({ spacing }) => (spacing ? tw`p-0-8 sm:p-1-6` : null)}
  ${({ cols }) => {
    if (cols === 1) return tw`w-full`
    if (cols === 2) return tw`w-1/2`
    if (cols > 2) return tw`w-1/2 lg:w-1/4`
  }}
  ${tw`flex flex-col`}
`

const ImageWrapper = tw.div`w-full`

const ContentWrapper = tw.div`flex-1 flex flex-col`

const Content = tw.div`flex-1`

const Title = styled.p`
  ${tw`block text-16 leading-1.36 sm:text-22 md:text-28 md:leading-1.21 md:font-medium font-display my-0-8 md:my-1-6`}
  hyphens: none;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
`

const SubTitle = tw.p`hidden md:block text-16 leading-1.75`

const StyledLink = styled(Link)`
  ${tw`flex-none md:hidden text-12 sm:text-16 leading-1.5 hover:text-orange`}
`

const FeaturedContentCard = withFeaturedContentCard(({ spacing, cols, title, subTitle, text, link, image }) => {
  return (
    <Wrapper spacing={spacing ? "true" : undefined} cols={cols}>
      <ImageWrapper>
        <ImageCTA image={image.src} buttonText={text} buttonLink={link} mobileFixed={!spacing} alt={image.alt} />
      </ImageWrapper>
      <ContentWrapper>
        <Content>
          {title && (
            <Link to={link}>
              <Title>{title}</Title>
            </Link>
          )}
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
        </Content>
        {title && (
          <StyledLink className={`underlined-black`} to={link}>
            <span>Read more</span>
          </StyledLink>
        )}
      </ContentWrapper>
    </Wrapper>
  )
})

FeaturedContentCard.propTypes = {
  spacing: PropTypes.bool,
  cols: PropTypes.number,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
}

export default FeaturedContentCard
